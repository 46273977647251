import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";

// Define the available backgrounds
export interface Background {
  id: string;
  name: string;
  path: string;
  thumbnail?: string;
  type: "image" | "animation" | "arwes";
  description: string;
}

// Define sound packs
export interface SoundPack {
  id: string;
  name: string;
  description: string;
  isAvailable: boolean; // For future implementation
}

// Define color schemes
export interface ColorScheme {
  id: string;
  name: string;
  primary: string;
  secondary: string;
  background: string;
  text: string;
  accent: string;
  warning: string;
  description: string;
}

// Define overall theme
export interface Theme {
  id: string;
  name: string;
  colorScheme: ColorScheme;
  background: Background;
  fontFamily: string;
  animationSpeed: number;
  soundEnabled: boolean;
  soundPack?: SoundPack; // Optional for now, will be required in future
  description: string;
}

// Define available sound packs (for future implementation)
const availableSoundPacks: SoundPack[] = [
  {
    id: "default",
    name: "Standard UI",
    description: "Default UI interaction sounds",
    isAvailable: true,
  },
  {
    id: "cyberpunk",
    name: "Cyberpunk",
    description: "Futuristic sci-fi interface sounds",
    isAvailable: false,
  },
  {
    id: "retro",
    name: "Retro Computing",
    description: "Vintage computer and terminal sounds",
    isAvailable: false,
  },
  {
    id: "minimal",
    name: "Minimal",
    description: "Subtle, quiet interface sounds",
    isAvailable: false,
  },
];

// Define available backgrounds
const availableBackgrounds: Background[] = [
  {
    id: "animated-grid",
    name: "Quantum Grid",
    path: "", // Not needed for arwes type
    type: "arwes",
    description: "Standard Arwes animated grid background",
  },
  {
    id: "animated-particles",
    name: "Neural Particles",
    path: "", // Animation handled by code
    type: "animation",
    description: "",
  },
  {
    id: "animated-waves",
    name: "Digital Waves",
    path: "", // Animation handled by code
    type: "animation",
    description: "",
  },
  {
    id: "fortress",
    name: "Fortress",
    path: "/images/backgrounds/Fortress.jpg",
    type: "image",
    description: "",
  },
  {
    id: "foreignland",
    name: "Foreign Land",
    path: "/images/backgrounds/Foreign%20Land.jpg",
    type: "image",
    description: "",
  },
  {
    id: "satellite",
    name: "Satellite",
    path: "/images/backgrounds/Satellite.jpg",
    type: "image",
    description: "",
  },
  {
    id: "dawnbreak",
    name: "Dawn Break",
    path: "/images/backgrounds/Dawn%20Break.jpg",
    type: "image",
    description: "",
  },
  {
    id: "thegrid",
    name: "The Grid",
    path: "/images/backgrounds/The%20Grid.jpg",
    type: "image",
    description: "",
  },
];

// Define available color schemes
const availableColorSchemes: ColorScheme[] = [
  {
    id: "aegis-blue",
    name: "Quantum Blue",
    primary: "#26dafd",
    secondary: "#0088cc",
    background: "#000814",
    text: "#ffffff",
    accent: "#51ff9d",
    warning: "#FFCC00",
    description: "Default A.E.G.I.S theme with bright blue accents",
  },
  {
    id: "quantum-green",
    name: "Matrix Green",
    primary: "#00ff00",
    secondary: "#00cc00",
    background: "#000a00",
    text: "#ffffff",
    accent: "#ffcc00",
    warning: "#FFCC00",
    description: "Classic terminal theme with bright green text",
  },
  {
    id: "neon-purple",
    name: "Cybernetic Purple",
    primary: "#aa00ff",
    secondary: "#7700cc",
    background: "#0a0014",
    text: "#ffffff",
    accent: "#ff00ff",
    warning: "#FFCC00",
    description: "High contrast purple theme with neon accents",
  },
  {
    id: "singularity-red",
    name: "Alert Red",
    primary: "#ff3333",
    secondary: "#cc0000",
    background: "#140000",
    text: "#ffffff",
    accent: "#ff9900",
    warning: "#ff9900",
    description: "High-alert status theme with intense red highlights",
  },
  {
    id: "holo-cyan",
    name: "Holographic Cyan",
    primary: "#00ffff",
    secondary: "#00cccc",
    background: "#001414",
    text: "#ffffff",
    accent: "#ff00ff",
    warning: "#FFCC00",
    description: "Holographic display theme with bright cyan elements",
  },
  {
    id: "amber-terminal",
    name: "Amber Terminal",
    primary: "#ffbf00",
    secondary: "#cc9600",
    background: "#140c00",
    text: "#ffffff",
    accent: "#66ff66",
    warning: "#ff6666",
    description: "Vintage amber monochrome display theme",
  },
];

// Define available themes
const availableThemes: Theme[] = [
  {
    id: "azimuth-one",
    name: "Azimuth One",
    colorScheme: availableColorSchemes[0],
    background: availableBackgrounds[0],
    fontFamily: '"Share Tech Mono", monospace',
    animationSpeed: 1,
    soundEnabled: true,
    soundPack: availableSoundPacks[0],
    description:
      "Primary Azimuth interface with animated quantum grid background",
  },
  {
    id: "terminal-classic",
    name: "Terminal Classic",
    colorScheme: availableColorSchemes[1],
    background: availableBackgrounds[0],
    fontFamily: '"Share Tech Mono", monospace',
    animationSpeed: 0.8,
    soundEnabled: true,
    soundPack: availableSoundPacks[0],
    description: "Classic terminal experience with green text",
  },
  {
    id: "cybernetic",
    name: "Cybernetic",
    colorScheme: availableColorSchemes[2],
    background: availableBackgrounds[2],
    fontFamily: '"Share Tech Mono", monospace',
    animationSpeed: 1.2,
    soundEnabled: true,
    soundPack: availableSoundPacks[0],
    description:
      "Futuristic interface with purple accents and animated background",
  },
  {
    id: "alert-status",
    name: "Alert Status",
    colorScheme: availableColorSchemes[3],
    background: availableBackgrounds[5],
    fontFamily: '"Share Tech Mono", monospace',
    animationSpeed: 1.5,
    soundEnabled: true,
    soundPack: availableSoundPacks[0],
    description: "High-alert status theme with red accents",
  },
  {
    id: "holographic",
    name: "Holographic Display",
    colorScheme: availableColorSchemes[4],
    background: availableBackgrounds[1],
    fontFamily: '"Share Tech Mono", monospace',
    animationSpeed: 1.3,
    soundEnabled: true,
    soundPack: availableSoundPacks[0],
    description: "Holographic interface simulation with cyan highlights",
  },
  {
    id: "retro-amber",
    name: "Retro Terminal",
    colorScheme: availableColorSchemes[5],
    background: availableBackgrounds[6],
    fontFamily: '"Share Tech Mono", monospace',
    animationSpeed: 0.7,
    soundEnabled: true,
    soundPack: availableSoundPacks[0],
    description: "Vintage amber terminal display with retro aesthetics",
  },
];

// Define context state
interface ThemeContextState {
  currentTheme: Theme;
  availableThemes: Theme[];
  availableBackgrounds: Background[];
  availableColorSchemes: ColorScheme[];
  availableSoundPacks: SoundPack[];
  setTheme: (themeId: string) => void;
  setBackground: (backgroundId: string) => void;
  setColorScheme: (colorSchemeId: string) => void;
  setSoundPack: (soundPackId: string) => void;
  toggleSounds: () => void;
  setAnimationSpeed: (speed: number) => void;
}

// Create the context
const ThemeContext = createContext<ThemeContextState | undefined>(undefined);

// Theme Provider component
interface ThemeProviderProps {
  children: ReactNode;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const [currentTheme, setCurrentTheme] = useState<Theme>(availableThemes[5]); // Default to Retro Terminal

  // Load saved theme from localStorage on component mount
  useEffect(() => {
    // Check if this is the first time the app is running
    const firstRun = localStorage.getItem("aegis-first-run") === null;

    if (firstRun) {
      // First run - set the Retro Terminal with Dawn Break background
      console.log(
        "First run detected - Setting default theme to Retro Terminal with Dawn Break background"
      );
      localStorage.setItem("aegis-theme", "retro-amber");
      localStorage.setItem("aegis-first-run", "false");
      // Current theme is already set to Retro Terminal by default
    } else {
      // Not first run - load saved theme if it exists
      const savedThemeId = localStorage.getItem("aegis-theme");
      console.log("Loading saved theme:", savedThemeId);
      if (savedThemeId) {
        const theme = availableThemes.find((t) => t.id === savedThemeId);
        if (theme) {
          setCurrentTheme(theme);
          console.log(
            "Applied saved theme:",
            theme.name,
            "with background:",
            theme.background.name
          );
        }
      }
    }
  }, []);

  // Save theme to localStorage when it changes
  useEffect(() => {
    localStorage.setItem("aegis-theme", currentTheme.id);
  }, [currentTheme]);

  // Set theme by ID
  const setTheme = (themeId: string) => {
    const theme = availableThemes.find((t) => t.id === themeId);
    if (theme) {
      setCurrentTheme(theme);
    }
  };

  // Set background
  const setBackground = (backgroundId: string) => {
    const background = availableBackgrounds.find((b) => b.id === backgroundId);
    if (background) {
      setCurrentTheme((prev) => ({
        ...prev,
        background,
      }));
    }
  };

  // Set color scheme
  const setColorScheme = (colorSchemeId: string) => {
    const colorScheme = availableColorSchemes.find(
      (c) => c.id === colorSchemeId
    );
    if (colorScheme) {
      setCurrentTheme((prev) => ({
        ...prev,
        colorScheme,
      }));
    }
  };

  // Set sound pack
  const setSoundPack = (soundPackId: string) => {
    const soundPack = availableSoundPacks.find((s) => s.id === soundPackId);
    if (soundPack && soundPack.isAvailable) {
      setCurrentTheme((prev) => ({
        ...prev,
        soundPack,
      }));
    }
  };

  // Toggle sounds
  const toggleSounds = () => {
    setCurrentTheme((prev) => ({
      ...prev,
      soundEnabled: !prev.soundEnabled,
    }));
  };

  // Set animation speed
  const setAnimationSpeed = (speed: number) => {
    setCurrentTheme((prev) => ({
      ...prev,
      animationSpeed: speed,
    }));
  };

  // Context value
  const value = {
    currentTheme,
    availableThemes,
    availableBackgrounds,
    availableColorSchemes,
    availableSoundPacks,
    setTheme,
    setBackground,
    setColorScheme,
    setSoundPack,
    toggleSounds,
    setAnimationSpeed,
  };

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

// Custom hook for using theme context
export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};
