import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  ReactNode,
  useEffect,
  useMemo,
} from "react";
import { preloadSounds } from "../utils/audioUtils";

// Define a track interface
interface MusicTrack {
  id: string;
  name: string;
  artist: string;
  path: string;
  duration: number; // in seconds
}

// Define UI sound types
export type UISoundType =
  | "click"
  | "hover"
  | "alert"
  | "confirm"
  | "error"
  | "notify"
  | "type"
  | "keystroke"
  | "glitch"
  | "boot"
  | "complete"
  | "restart"
  | "shutdown";

// UI Sound paths - using public directory
const uiSoundPaths: Record<UISoundType, string> = {
  click: "/sounds/ui/click.mp3",
  hover: "/sounds/ui/hover.mp3",
  alert: "/sounds/ui/alert.mp3",
  confirm: "/sounds/ui/confirm.mp3",
  error: "/sounds/ui/error.mp3",
  notify: "/sounds/ui/notify.mp3",
  type: "/sounds/ui/type.mp3",
  keystroke: "/sounds/ui/keystroke.mp3",
  glitch: "/sounds/ui/glitch.mp3",
  boot: "/sounds/ui/boot.mp3",
  complete: "/sounds/ui/complete.mp3",
  restart: "/sounds/ui/restart.mp3",
  shutdown: "/sounds/ui/shutdown.mp3",
};

// Define the shape of the context value
interface AudioContextValue {
  playUISound: (soundName: string) => Promise<boolean>;
  isAudioInitialized: boolean;
  isAudioEnabled: boolean;
  isSoundEnabled: boolean;
  toggleSound: () => void;
  soundVolume: number;
  setSoundVolume: (volume: number) => void;

  // Music player functionality
  currentTrack: MusicTrack | null;
  isPlaying: boolean;
  currentTime: number;
  musicVolume: number;
  isMusicEnabled: boolean;
  availableMusicTracks: MusicTrack[];
  playMusic: (trackId: string) => void;
  pauseMusic: () => void;
  resumeMusic: () => void;
  nextTrack: () => void;
  previousTrack: () => void;
  setMusicVolume: (volume: number) => void;
  seekTo: (time: number) => void;
}

// Create the context with an undefined initial value
const AudioContext = createContext<AudioContextValue | undefined>(undefined);

// Define the props for the provider
interface AudioProviderProps {
  children: ReactNode;
}

// Create the provider component
export const AudioProvider: React.FC<AudioProviderProps> = ({ children }) => {
  const [isAudioInitialized, setIsAudioInitialized] = useState(false);
  const [isAudioEnabled] = useState(true); // System-level audio enabled flag
  const [isSoundEnabled, setIsSoundEnabled] = useState(true);
  const [soundVolume, setSoundVolume] = useState(0.7); // Default volume at 70%

  // Music player state
  const [currentTrack, setCurrentTrack] = useState<MusicTrack | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [musicVolume, setMusicVolume] = useState(0.5); // Default music volume
  const [isMusicEnabled, setIsMusicEnabled] = useState(true);
  const [availableMusicTracks, setAvailableMusicTracks] = useState<
    MusicTrack[]
  >([]);
  const [audioElement, setAudioElement] = useState<HTMLAudioElement | null>(
    null
  );

  // UI sound state
  // Note: These variables are reserved for future implementation
  // of more advanced sound capabilities
  const [_uiSoundVolume, _setUISoundVolume] = useState(0.5);
  const [_isUISoundEnabled, _setUISoundEnabled] = useState(true);
  const [_uiSoundElements, _setUISoundElements] = useState<
    Record<UISoundType, HTMLAudioElement | null>
  >({
    click: null,
    hover: null,
    alert: null,
    confirm: null,
    error: null,
    notify: null,
    type: null,
    keystroke: null,
    glitch: null,
    boot: null,
    complete: null,
    restart: null,
    shutdown: null,
  });

  // Load music tracks
  useEffect(() => {
    const loadMusicTracks = async () => {
      try {
        // These tracks match files in public/sounds/music/
        const tracksData: MusicTrack[] = [
          {
            id: "unmoored",
            name: "Unmoored",
            artist: "Onirocrisia \ud834\udd1e",
            path: "/sounds/music/unmoored.wav",
            duration: 180,
          },
          {
            id: "prominence",
            name: "Prominence",
            artist: "Onirocrisia \ud834\udd1e",
            path: "/sounds/music/prominence.mp3",
            duration: 240,
          },
          {
            id: "out-the-wired",
            name: "Out the Wired",
            artist: "Onirocrisia \ud834\udd1e",
            path: "/sounds/music/out-the-wired.mp3",
            duration: 210,
          },
          {
            id: "kinesis",
            name: "Kinesis",
            artist: "Onirocrisia \ud834\udd1e",
            path: "/sounds/music/kinesis.wav",
            duration: 260,
          },
          {
            id: "ground-zero",
            name: "Ground Zero",
            artist: "Onirocrisia \ud834\udd1e",
            path: "/sounds/music/ground-zero.wav",
            duration: 195,
          },
          {
            id: "discordant-aether",
            name: "Discordant Aether",
            artist: "Onirocrisia \ud834\udd1e",
            path: "/sounds/music/discordant-aether.wav",
            duration: 215,
          },
          {
            id: "zenith",
            name: "Zenith",
            artist: "Onirocrisia \ud834\udd1e",
            path: "/sounds/music/Zenith.mp3",
            duration: 230,
          },
          {
            id: "arctic-form",
            name: "What If The Depths Had Eyes? Arctic Form",
            artist: "Onirocrisia \ud834\udd1e",
            path: "/sounds/music/What If The Depths Had Eyes_ Arctic Form-1.mp3",
            duration: 285,
          },
          {
            id: "waking-abeyance",
            name: "Waking Abeyance (Cross your heart remix)",
            artist: "Onirocrisia \ud834\udd1e",
            path: "/sounds/music/Waking Abeyance (Cross your heart remix).mp3",
            duration: 195,
          },
          {
            id: "vigilance-obscuration",
            name: "Vigilance Obscuration",
            artist: "Onirocrisia \ud834\udd1e",
            path: "/sounds/music/Vigilance Obscuration.mp3",
            duration: 175,
          },
          {
            id: "the-light-is-winning",
            name: "The Light Is Winning",
            artist: "Onirocrisia \ud834\udd1e",
            path: "/sounds/music/The Light Is Winning.mp3",
            duration: 180,
          },
          {
            id: "sanctuary",
            name: "Sanctuary",
            artist: "Onirocrisia \ud834\udd1e",
            path: "/sounds/music/Sanctuary.mp3",
            duration: 200,
          },
          {
            id: "resurrection-syndrome",
            name: "Resurrection Syndrome",
            artist: "Onirocrisia \ud834\udd1e",
            path: "/sounds/music/Resurrection Syndrome.mp3",
            duration: 205,
          },
          {
            id: "orions-trail",
            name: "Orion's Trail",
            artist: "Onirocrisia \ud834\udd1e",
            path: "/sounds/music/Orion's Trail.mp3",
            duration: 190,
          },
          {
            id: "in-the-dark",
            name: "In the Dark",
            artist: "Onirocrisia \ud834\udd1e",
            path: "/sounds/music/In the dark.mp3",
            duration: 185,
          },
          {
            id: "ignoring-unseen-guests",
            name: "Ignoring Unseen Guests at Daybreak",
            artist: "Onirocrisia \ud834\udd1e",
            path: "/sounds/music/Ignoring unseen guests at daybreak.mp3",
            duration: 195,
          },
          {
            id: "deluvian",
            name: "Deluvian",
            artist: "Onirocrisia \ud834\udd1e",
            path: "/sounds/music/Deluvian.mp3",
            duration: 210,
          },
          {
            id: "chess-is-a-war-game",
            name: "Chess Is a War Game",
            artist: "Onirocrisia \ud834\udd1e",
            path: "/sounds/music/Chess is a war game.mp3",
            duration: 220,
          },
          {
            id: "the-weight-of-being",
            name: "The Weight of Being",
            artist: "Onirocrisia \ud834\udd1e",
            path: "/sounds/music/The Weight of Being.wav",
            duration: 250,
          },
          {
            id: "crimson-storm",
            name: "Crimson Storm",
            artist: "Onirocrisia \ud834\udd1e",
            path: "/sounds/music/Crimson Storm.wav",
            duration: 240,
          },
        ];

        // Create an audio element for music playback
        const audio = new Audio();
        audio.volume = musicVolume;

        // Set up time update listener
        audio.addEventListener("timeupdate", () => {
          setCurrentTime(audio.currentTime);
        });

        // Handle track ended
        audio.addEventListener("ended", () => {
          nextTrack();
        });

        // Load actual durations when metadata is available
        const loadTrackMetadata = (track: MusicTrack): Promise<MusicTrack> => {
          return new Promise((resolve) => {
            const tempAudio = new Audio(track.path);
            tempAudio.addEventListener("loadedmetadata", () => {
              resolve({
                ...track,
                duration: tempAudio.duration,
              });
            });
            // Fallback if metadata doesn't load
            tempAudio.addEventListener("error", () => {
              console.warn(`Failed to load metadata for track: ${track.name}`);
              resolve(track);
            });
          });
        };

        // Load all track metadata in parallel
        const tracksWithMetadata = await Promise.all(
          tracksData.map(loadTrackMetadata)
        );

        setAvailableMusicTracks(tracksWithMetadata);
        setAudioElement(audio);

        console.log(
          "[AudioProvider] Music tracks loaded:",
          tracksWithMetadata.length
        );
      } catch (error) {
        console.error("[AudioProvider] Failed to load music tracks:", error);
        setAvailableMusicTracks([]);
      }
    };

    loadMusicTracks();
  }, []);

  useEffect(() => {
    const initAudio = async () => {
      try {
        // Initialize AudioContext after user interaction if needed (handled in App.tsx)
        // Preload sounds
        await preloadSounds();
        console.log(
          "[AudioProvider] Audio system initialized and sounds preloaded."
        );
        setIsAudioInitialized(true);

        // Load saved sound settings from localStorage
        const savedVolume = localStorage.getItem("aegis-sound-volume");
        const savedSoundEnabled = localStorage.getItem("aegis-sound-enabled");
        const savedMusicVolume = localStorage.getItem("aegis-music-volume");
        const savedMusicEnabled = localStorage.getItem("aegis-music-enabled");

        if (savedVolume) {
          setSoundVolume(parseFloat(savedVolume));
        }

        if (savedSoundEnabled) {
          setIsSoundEnabled(savedSoundEnabled === "true");
        }

        if (savedMusicVolume) {
          setMusicVolume(parseFloat(savedMusicVolume));
        }

        if (savedMusicEnabled) {
          setIsMusicEnabled(savedMusicEnabled === "true");
        }
      } catch (error) {
        console.error(
          "[AudioProvider] Failed to initialize audio system:",
          error
        );
      }
    };

    initAudio();

    // Cleanup function
    return () => {
      if (audioElement) {
        audioElement.pause();
        audioElement.src = "";
      }
    };
  }, []);

  // Save sound settings to localStorage when they change
  useEffect(() => {
    localStorage.setItem("aegis-sound-volume", soundVolume.toString());
  }, [soundVolume]);

  useEffect(() => {
    localStorage.setItem("aegis-sound-enabled", isSoundEnabled.toString());
  }, [isSoundEnabled]);

  useEffect(() => {
    localStorage.setItem("aegis-music-volume", musicVolume.toString());
    if (audioElement) {
      audioElement.volume = musicVolume;
    }
  }, [musicVolume, audioElement]);

  useEffect(() => {
    localStorage.setItem("aegis-music-enabled", isMusicEnabled.toString());
  }, [isMusicEnabled]);

  // Toggle sound enabled/disabled
  const toggleSound = useCallback(() => {
    setIsSoundEnabled((prev) => !prev);
  }, []);

  // Music player functions
  const playMusic = useCallback(
    (trackId: string) => {
      const trackToPlay = availableMusicTracks.find((t) => t.id === trackId);
      if (trackToPlay && audioElement) {
        // Pause current playback before switching
        if (!audioElement.paused) {
          audioElement.pause();
          // Optional: Reset time if you always want tracks to start from beginning
          // audioElement.currentTime = 0;
        }

        // Set new source and play
        audioElement.src = trackToPlay.path;
        // Ensure volume is set correctly before playing
        audioElement.volume = musicVolume;
        audioElement
          .play()
          .then(() => {
            setCurrentTrack(trackToPlay);
            setIsPlaying(true);
            // Start time tracking from 0 or current position if resuming same track
            setCurrentTime(audioElement.currentTime);
          })
          .catch((error) => {
            console.error("Error playing music:", error);
            setIsPlaying(false); // Ensure state is correct on error
          });
      }
    },
    [availableMusicTracks, audioElement, musicVolume] // Add musicVolume dependency
  );

  const pauseMusic = useCallback(() => {
    if (!audioElement || !isPlaying) return;
    audioElement.pause();
    setIsPlaying(false);
  }, [audioElement, isPlaying]);

  const resumeMusic = useCallback(() => {
    if (!audioElement || isPlaying || !currentTrack) return;
    audioElement.play().catch((err) => {
      console.error("[AudioProvider] Failed to resume music:", err);
    });
    setIsPlaying(true);
  }, [audioElement, isPlaying, currentTrack]);

  const nextTrack = useCallback(() => {
    if (!currentTrack || availableMusicTracks.length === 0) return;

    const currentIndex = availableMusicTracks.findIndex(
      (t) => t.id === currentTrack.id
    );
    const nextIndex = (currentIndex + 1) % availableMusicTracks.length;
    playMusic(availableMusicTracks[nextIndex].id);
  }, [currentTrack, availableMusicTracks, playMusic]);

  const previousTrack = useCallback(() => {
    if (!currentTrack || availableMusicTracks.length === 0) return;

    const currentIndex = availableMusicTracks.findIndex(
      (t) => t.id === currentTrack.id
    );
    const prevIndex =
      (currentIndex - 1 + availableMusicTracks.length) %
      availableMusicTracks.length;
    playMusic(availableMusicTracks[prevIndex].id);
  }, [currentTrack, availableMusicTracks, playMusic]);

  const seekTo = useCallback(
    (time: number) => {
      if (!audioElement) return;
      audioElement.currentTime = time;
      setCurrentTime(time);
    },
    [audioElement]
  );

  // Simplified playUISound for basic testing
  const playUISound = useCallback(
    (soundName: string) => {
      if (!isAudioInitialized || !isAudioEnabled || !isSoundEnabled) {
        console.log(
          "[AudioContext] Audio not ready or disabled, skipping play:",
          soundName
        );
        return Promise.resolve(false);
      }

      const soundPath = `/sounds/ui/${soundName}.mp3`;
      console.log("[AudioContext] Attempting to play:", soundPath);

      return new Promise<boolean>((resolve) => {
        try {
          const audio = new Audio(soundPath);
          audio.volume = soundVolume;

          // Special handling for restart and shutdown sounds
          if (soundName === "restart" || soundName === "shutdown") {
            // These are important system sounds, so let's preload them
            audio.preload = "auto";

            // Ensure we can access these sounds later
            console.log(`[AudioContext] Preparing ${soundName} sound`);

            // Set up event listeners for important sounds
            audio.addEventListener("canplaythrough", () => {
              console.log(`[AudioContext] ${soundName} sound ready to play`);
              audio
                .play()
                .then(() => {
                  console.log(`[AudioContext] ${soundName} sound playing`);
                  resolve(true);
                })
                .catch((error) => {
                  console.error(
                    `[AudioContext] Failed to play ${soundName} sound:`,
                    error
                  );
                  // Try alternative approach for browsers with strict autoplay policies
                  const context = new (window.AudioContext ||
                    (window as any).webkitAudioContext)();
                  const source = context.createBufferSource();

                  fetch(soundPath)
                    .then((response) => response.arrayBuffer())
                    .then((arrayBuffer) => context.decodeAudioData(arrayBuffer))
                    .then((audioBuffer) => {
                      source.buffer = audioBuffer;
                      source.connect(context.destination);
                      source.start(0);
                      console.log(
                        `[AudioContext] ${soundName} playing via AudioContext API`
                      );
                      resolve(true);
                    })
                    .catch((error) => {
                      console.error(
                        `[AudioContext] Complete failure to play ${soundName}:`,
                        error
                      );
                      resolve(false);
                    });
                });
            });

            audio.addEventListener("error", (e) => {
              console.error(
                `[AudioContext] Error loading ${soundName} sound:`,
                e
              );
              resolve(false);
            });

            // Timeout for loading
            setTimeout(() => {
              if (audio.readyState < 4) {
                // Not loaded
                console.warn(
                  `[AudioContext] Timeout loading ${soundName} sound, attempting playback anyway`
                );
                audio.play().catch(() => {
                  console.error(
                    `[AudioContext] Fallback ${soundName} play failed`
                  );
                  resolve(false);
                });
              }
            }, 1000);
          } else {
            // Standard sound handling for other UI sounds
            audio
              .play()
              .then(() => {
                console.log("[AudioContext] Play succeeded for:", soundPath);
                resolve(true);
              })
              .catch((error) => {
                console.error(
                  "[AudioContext] Play failed for:",
                  soundPath,
                  error
                );
                resolve(false);
              });
          }

          audio.onerror = (e) => {
            console.error(
              "[AudioContext] Audio error event for:",
              soundPath,
              e
            );
            resolve(false);
          };
        } catch (error) {
          console.error(
            "[AudioContext] Error creating Audio element for:",
            soundPath,
            error
          );
          resolve(false);
        }
      });
    },
    [isAudioInitialized, isAudioEnabled, isSoundEnabled, soundVolume]
  );

  const value = useMemo(
    () => ({
      isAudioInitialized,
      isAudioEnabled,
      playUISound,
      isSoundEnabled,
      toggleSound,
      soundVolume,
      setSoundVolume,
      // Music player
      currentTrack,
      isPlaying,
      currentTime,
      musicVolume,
      isMusicEnabled,
      availableMusicTracks,
      playMusic,
      pauseMusic,
      resumeMusic,
      nextTrack,
      previousTrack,
      setMusicVolume,
      seekTo,
    }),
    [
      isAudioInitialized,
      isAudioEnabled,
      playUISound,
      isSoundEnabled,
      toggleSound,
      soundVolume,
      // Music player deps
      currentTrack,
      isPlaying,
      currentTime,
      musicVolume,
      isMusicEnabled,
      availableMusicTracks,
      playMusic,
      pauseMusic,
      resumeMusic,
      nextTrack,
      previousTrack,
      setMusicVolume,
      seekTo,
    ]
  );

  return (
    <AudioContext.Provider value={value}>{children}</AudioContext.Provider>
  );
};

// Create the custom hook for consuming the context
export const useAudio = (): AudioContextValue => {
  const context = useContext(AudioContext);
  if (context === undefined) {
    throw new Error("useAudio must be used within an AudioProvider");
  }
  return context;
};
