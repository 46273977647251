import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  ReactNode,
  useMemo,
  lazy,
} from "react";
// Removed unused direct component imports
// import ThemeSelector from '../components/ThemeSelector';
// import Terminal from '../components/Terminal';
// import FileExplorer from '../components/FileExplorer';
// import QuantumVisualizer from '../components/QuantumVisualizer';

// Lazy load components - Back to React.FC<any>
const TerminalLazy: React.LazyExoticComponent<React.FC<any>> = lazy(
  () => import("../components/Terminal")
);
const FileExplorerLazy: React.LazyExoticComponent<React.FC<any>> = lazy(
  () => import("../components/FileExplorer")
);
const SystemMonitorLazy: React.LazyExoticComponent<React.FC<any>> = lazy(
  () => import("../components/SystemMonitor") // NOTE: Ensure this file is renamed to QuantumVisualizer.tsx
);
const ThemeSelectorLazy: React.LazyExoticComponent<React.FC<any>> = lazy(
  () => import("../components/ThemeSelector")
);
const LogsLazy: React.LazyExoticComponent<React.FC<any>> = lazy(
  () => import("../components/Logs")
);
const CodexLazy: React.LazyExoticComponent<React.FC<any>> = lazy(
  () => import("../components/Codex")
);
const MusicPlayerLazy: React.LazyExoticComponent<React.FC<any>> = lazy(
  () => import("../components/MusicPlayer")
);
// NEW: Lazy load QuantumVisualizer
const QuantumVisualizerLazy: React.LazyExoticComponent<React.FC<any>> = lazy(
  () => import("../components/QuantumVisualizer")
);
// Removed MusicPlayer import

// Define window types using an enum for better type safety
export enum WindowType {
  Terminal = "Terminal",
  FileExplorer = "FileExplorer",
  SystemMonitor = "SystemMonitor", // Renamed from QuantumVisualizer
  ThemeSelector = "ThemeSelector",
  Logs = "Logs", // Added Logs
  Codex = "Codex", // Added Codex
  MusicPlayer = "MusicPlayer", // Add MusicPlayer
  QuantumVisualizer = "QuantumVisualizer", // Added new type
  // Removed MusicPlayer
  // TextEditor = 'text-editor', // Keep if planning to implement
  // ImageViewer = 'image-viewer', // Keep if planning to implement
  Custom = "custom", // For non-standard windows
}

// Define the shape of a window
export interface WindowData {
  id: string;
  title: string;
  appId: WindowType; // Use the enum type
  position: { x: number; y: number };
  size: { width: number; height: number };
  minSize: { width: number; height: number };
  isMinimized?: boolean;
  isMaximized?: boolean;
  zIndex?: number;
}

// Define the context state
interface WindowManagerContextState {
  windows: WindowData[];
  activeWindowId: string | null;
  addWindow: (
    window: Omit<WindowData, "id" | "zIndex"> & { id?: string }
  ) => void;
  removeWindow: (id: string) => void;
  activateWindow: (id: string) => void;
  minimizeWindow: (id: string) => void;
  maximizeWindow: (id: string) => void;
  restoreWindow: (id: string) => void;
  updateWindowPosition: (
    id: string,
    position: { x: number; y: number }
  ) => void;
  updateWindowSize: (
    id: string,
    size: { width: number; height: number }
  ) => void;
  getComponentForAppId: (
    appId: WindowType
  ) => React.LazyExoticComponent<React.FC<any>>; // Back to any

  minimizeAllWindows: () => void;

  // NEW FUNCTIONS:
  snapWindowToPosition: (
    id: string,
    position:
      | "left"
      | "right"
      | "top"
      | "bottom"
      | "top-left"
      | "top-right"
      | "bottom-left"
      | "bottom-right"
      | "center"
      | "maximize"
  ) => void;
  arrangeWindows: (
    layout: "grid" | "cascade" | "horizontal" | "vertical"
  ) => void;
  saveWindowState: (id: string) => void;
  restoreWindowState: (id: string) => void;
  focusNextWindow: () => void;
  focusPreviousWindow: () => void;
  getWindowsByAppId: (appId: WindowType) => WindowData[];
}

// Create the context
const WindowManagerContext = createContext<
  WindowManagerContextState | undefined
>(undefined);

// Window Manager Provider component
interface WindowManagerProviderProps {
  children: ReactNode;
}

let nextZIndex = 100; // Initial z-index for the first window

export const WindowManagerProvider: React.FC<WindowManagerProviderProps> = ({
  children,
}) => {
  // Use useState/useCallback as previously established
  const [windows, setWindows] = useState<WindowData[]>([]);
  const [activeWindowId, setActiveWindowId] = useState<string | null>(null);

  // Add this new state
  const [savedWindowStates, setSavedWindowStates] = useState<
    Record<string, Omit<WindowData, "id">>
  >({});

  const bringToFront = useCallback((id: string) => {
    nextZIndex += 1;
    setWindows((prevWindows) =>
      prevWindows.map((win) =>
        win.id === id ? { ...win, zIndex: nextZIndex } : win
      )
    );
    setActiveWindowId(id);
  }, []);

  const addWindow = useCallback(
    (windowConfig: Omit<WindowData, "id" | "zIndex"> & { id?: string }) => {
      // Allow multiple instances, focus existing if already open?
      // This implementation adds a new window every time.
      const newWindow: WindowData = {
        ...windowConfig,
        id: windowConfig.id || `${windowConfig.appId}-${Date.now()}`,
        position: windowConfig.position || {
          x: 50 + Math.random() * 50,
          y: 50 + Math.random() * 50,
        }, // Add randomness
        size: windowConfig.size || { width: 600, height: 400 },
        minSize: windowConfig.minSize || { width: 200, height: 150 },
        isMinimized: false,
        isMaximized: false,
        zIndex: nextZIndex + 1,
      };
      nextZIndex += 1;
      setWindows((prevWindows) => [...prevWindows, newWindow]);
      setActiveWindowId(newWindow.id);
    },
    []
  );

  const removeWindow = useCallback(
    (id: string) => {
      setWindows((prevWindows) => {
        const remainingWindows = prevWindows.filter((win) => win.id !== id);
        if (activeWindowId === id) {
          // If the removed window was active, find the next highest z-index window to activate
          if (remainingWindows.length > 0) {
            const nextActive = remainingWindows.reduce((prev, current) =>
              (prev.zIndex || 0) > (current.zIndex || 0) ? prev : current
            );
            setActiveWindowId(nextActive.id);
          } else {
            setActiveWindowId(null);
          }
        }
        return remainingWindows;
      });
    },
    [activeWindowId]
  ); // Depend on activeWindowId

  const activateWindow = useCallback(
    (id: string) => {
      if (activeWindowId !== id) {
        bringToFront(id);
      }
    },
    [activeWindowId, bringToFront]
  );

  const minimizeWindow = useCallback(
    (id: string) => {
      setWindows((prevWindows) =>
        prevWindows.map((win) =>
          win.id === id ? { ...win, isMinimized: true } : win
        )
      );
      if (activeWindowId === id) {
        setActiveWindowId(null);
      }
    },
    [activeWindowId]
  );

  const maximizeWindow = useCallback(
    (id: string) => {
      setWindows((prevWindows) =>
        prevWindows.map((win) =>
          win.id === id
            ? { ...win, isMaximized: true, isMinimized: false }
            : win
        )
      );
      bringToFront(id);
    },
    [bringToFront]
  );

  const restoreWindow = useCallback(
    (id: string) => {
      setWindows((prevWindows) =>
        prevWindows.map((win) =>
          win.id === id
            ? { ...win, isMinimized: false, isMaximized: false }
            : win
        )
      );
      bringToFront(id);
    },
    [bringToFront]
  );

  const updateWindowPosition = useCallback(
    (id: string, position: { x: number; y: number }) => {
      setWindows((prevWindows) =>
        prevWindows.map((win) => (win.id === id ? { ...win, position } : win))
      );
    },
    []
  );

  const updateWindowSize = useCallback(
    (id: string, size: { width: number; height: number }) => {
      setWindows((prevWindows) =>
        prevWindows.map((win) => (win.id === id ? { ...win, size } : win))
      );
    },
    []
  );

  // Function to get the correct lazy component based on appId
  const getComponentForAppId = useCallback(
    (appId: WindowType): React.LazyExoticComponent<React.FC<any>> => {
      switch (appId) {
        case WindowType.Terminal:
          return TerminalLazy;
        case WindowType.FileExplorer:
          return FileExplorerLazy;
        case WindowType.SystemMonitor:
          return SystemMonitorLazy;
        case WindowType.ThemeSelector:
          return ThemeSelectorLazy;
        case WindowType.Logs:
          return LogsLazy;
        case WindowType.Codex:
          return CodexLazy;
        case WindowType.MusicPlayer:
          return MusicPlayerLazy;
        case WindowType.QuantumVisualizer:
          return QuantumVisualizerLazy;
        default:
          // Fallback or error component if needed
          // For now, returning a simple div or null, but potentially throw error
          // This depends on how you want to handle unknown types
          // Returning TerminalLazy as a temporary default to avoid crashes
          console.error(`Unknown appId: ${appId}. Falling back to Terminal.`);
          // You might want a dedicated ErrorComponent or PlaceholderComponent here
          return TerminalLazy; // Or a specific Placeholder/Error component
      }
    },
    []
  );

  // Add window snapping functionality
  const snapWindowToPosition = useCallback(
    (
      id: string,
      position:
        | "left"
        | "right"
        | "top"
        | "bottom"
        | "top-left"
        | "top-right"
        | "bottom-left"
        | "bottom-right"
        | "center"
        | "maximize"
    ) => {
      const window = windows.find((w) => w.id === id);
      if (!window) return;

      // Get viewport dimensions (adjust if you have specific desktop bounds)
      const viewportWidth =
        window.innerWidth || document.documentElement.clientWidth;
      const viewportHeight =
        window.innerHeight || document.documentElement.clientHeight;

      // Calculate new position and size based on the snap position
      let newPosition = { x: 0, y: 0 };
      let newSize = { ...window.size };

      // Default padding from edges
      const padding = 10;
      const halfWidth = Math.floor(viewportWidth / 2) - padding;
      const halfHeight = Math.floor(viewportHeight / 2) - padding;

      switch (position) {
        case "left":
          newPosition = { x: padding, y: padding };
          newSize = { width: halfWidth, height: viewportHeight - padding * 2 };
          break;
        case "right":
          newPosition = { x: halfWidth + padding, y: padding };
          newSize = { width: halfWidth, height: viewportHeight - padding * 2 };
          break;
        case "top":
          newPosition = { x: padding, y: padding };
          newSize = { width: viewportWidth - padding * 2, height: halfHeight };
          break;
        case "bottom":
          newPosition = { x: padding, y: halfHeight + padding };
          newSize = { width: viewportWidth - padding * 2, height: halfHeight };
          break;
        case "top-left":
          newPosition = { x: padding, y: padding };
          newSize = { width: halfWidth, height: halfHeight };
          break;
        case "top-right":
          newPosition = { x: halfWidth + padding, y: padding };
          newSize = { width: halfWidth, height: halfHeight };
          break;
        case "bottom-left":
          newPosition = { x: padding, y: halfHeight + padding };
          newSize = { width: halfWidth, height: halfHeight };
          break;
        case "bottom-right":
          newPosition = { x: halfWidth + padding, y: halfHeight + padding };
          newSize = { width: halfWidth, height: halfHeight };
          break;
        case "center":
          // Center window while maintaining its size
          newPosition = {
            x: Math.max(
              padding,
              Math.floor((viewportWidth - window.size.width) / 2)
            ),
            y: Math.max(
              padding,
              Math.floor((viewportHeight - window.size.height) / 2)
            ),
          };
          break;
        case "maximize":
          // Same as maximize window but using the snap API
          maximizeWindow(id);
          return;
      }

      // Update window position and size
      updateWindowPosition(id, newPosition);
      updateWindowSize(id, newSize);
      bringToFront(id);
    },
    [
      windows,
      maximizeWindow,
      updateWindowPosition,
      updateWindowSize,
      bringToFront,
    ]
  );

  // Arrange multiple windows in different layouts
  const arrangeWindows = useCallback(
    (layout: "grid" | "cascade" | "horizontal" | "vertical") => {
      if (windows.length === 0) return;

      // Get viewport dimensions
      const viewportWidth =
        window.innerWidth || document.documentElement.clientWidth;
      const viewportHeight =
        window.innerHeight || document.documentElement.clientHeight;
      const padding = 20;

      // Create a copy of visible windows (non-minimized)
      const visibleWindows = windows.filter((w) => !w.isMinimized);

      switch (layout) {
        case "grid": {
          // Calculate grid dimensions
          const count = visibleWindows.length;
          const cols = Math.ceil(Math.sqrt(count));
          const rows = Math.ceil(count / cols);

          // Calculate cell size
          const cellWidth = Math.floor((viewportWidth - padding * 2) / cols);
          const cellHeight = Math.floor((viewportHeight - padding * 2) / rows);

          visibleWindows.forEach((win, index) => {
            const row = Math.floor(index / cols);
            const col = index % cols;

            updateWindowPosition(win.id, {
              x: padding + col * cellWidth,
              y: padding + row * cellHeight,
            });

            updateWindowSize(win.id, {
              width: cellWidth - padding,
              height: cellHeight - padding,
            });
          });
          break;
        }
        case "cascade": {
          // Cascade windows with slight offset
          const offset = 30;
          visibleWindows.forEach((win, index) => {
            updateWindowPosition(win.id, {
              x: padding + index * offset,
              y: padding + index * offset,
            });

            // Set uniform size for cascade
            updateWindowSize(win.id, {
              width: Math.min(
                600,
                viewportWidth - padding * 2 - index * offset
              ),
              height: Math.min(
                400,
                viewportHeight - padding * 2 - index * offset
              ),
            });

            // Bring to front in reverse order so last window is on top
            setTimeout(() => {
              bringToFront(
                visibleWindows[visibleWindows.length - 1 - index].id
              );
            }, 10 * index);
          });
          break;
        }
        case "horizontal": {
          // Stack windows horizontally
          const windowWidth = Math.floor(
            (viewportWidth - padding * 2) / visibleWindows.length
          );
          visibleWindows.forEach((win, index) => {
            updateWindowPosition(win.id, {
              x: padding + index * windowWidth,
              y: padding,
            });

            updateWindowSize(win.id, {
              width: windowWidth - padding,
              height: viewportHeight - padding * 2,
            });
          });
          break;
        }
        case "vertical": {
          // Stack windows vertically
          const windowHeight = Math.floor(
            (viewportHeight - padding * 2) / visibleWindows.length
          );
          visibleWindows.forEach((win, index) => {
            updateWindowPosition(win.id, {
              x: padding,
              y: padding + index * windowHeight,
            });

            updateWindowSize(win.id, {
              width: viewportWidth - padding * 2,
              height: windowHeight - padding,
            });
          });
          break;
        }
      }
    },
    [windows, updateWindowPosition, updateWindowSize, bringToFront]
  );

  // Save window state
  const saveWindowState = useCallback(
    (id: string) => {
      const windowToSave = windows.find((win) => win.id === id);
      if (windowToSave) {
        // Create stateToSave without the 'id' property
        const stateToSave: Omit<WindowData, "id"> = {
          title: windowToSave.title,
          appId: windowToSave.appId,
          position: windowToSave.position,
          size: windowToSave.size,
          minSize: windowToSave.minSize,
          isMinimized: windowToSave.isMinimized,
          isMaximized: windowToSave.isMaximized,
          zIndex: windowToSave.zIndex,
        };
        setSavedWindowStates((prev) => ({
          ...prev,
          [id]: stateToSave,
        }));
      }
    },
    [windows]
  );

  // Restore window state
  const restoreWindowState = useCallback(
    (id: string) => {
      const savedState = savedWindowStates[id];
      if (!savedState) return;

      // Restore position and size
      updateWindowPosition(id, savedState.position);
      updateWindowSize(id, savedState.size);

      // Restore minimized/maximized state
      if (savedState.isMaximized) {
        maximizeWindow(id);
      } else if (savedState.isMinimized) {
        minimizeWindow(id);
      } else {
        restoreWindow(id);
      }
    },
    [
      savedWindowStates,
      updateWindowPosition,
      updateWindowSize,
      maximizeWindow,
      minimizeWindow,
      restoreWindow,
    ]
  );

  // Focus navigation between windows
  const focusNextWindow = useCallback(() => {
    if (windows.length <= 1) return;

    // Sort windows by z-index
    const sortedWindows = [...windows]
      .filter((w) => !w.isMinimized)
      .sort((a, b) => (a.zIndex || 0) - (b.zIndex || 0));

    if (sortedWindows.length === 0) return;

    // Find current active window index
    const activeIndex = sortedWindows.findIndex((w) => w.id === activeWindowId);

    // Get next window (or first if at end or none active)
    const nextIndex =
      activeIndex === -1 || activeIndex === sortedWindows.length - 1
        ? 0
        : activeIndex + 1;

    // Activate next window
    activateWindow(sortedWindows[nextIndex].id);
  }, [windows, activeWindowId, activateWindow]);

  const focusPreviousWindow = useCallback(() => {
    if (windows.length <= 1) return;

    // Sort windows by z-index
    const sortedWindows = [...windows]
      .filter((w) => !w.isMinimized)
      .sort((a, b) => (a.zIndex || 0) - (b.zIndex || 0));

    if (sortedWindows.length === 0) return;

    // Find current active window index
    const activeIndex = sortedWindows.findIndex((w) => w.id === activeWindowId);

    // Get previous window (or last if at beginning or none active)
    const prevIndex =
      activeIndex === -1 || activeIndex === 0
        ? sortedWindows.length - 1
        : activeIndex - 1;

    // Activate previous window
    activateWindow(sortedWindows[prevIndex].id);
  }, [windows, activeWindowId, activateWindow]);

  // Get windows by app type
  const getWindowsByAppId = useCallback(
    (appId: WindowType) => {
      return windows.filter((w) => w.appId === appId);
    },
    [windows]
  );

  // Add the minimize all windows function implementation
  const minimizeAllWindows = useCallback(() => {
    setWindows((prevWindows) =>
      prevWindows.map((win) => ({
        ...win,
        isMinimized: true,
      }))
    );
    setActiveWindowId(null);
  }, []);

  // Add the new functions to the context value
  const contextValue = useMemo(
    () => ({
      windows,
      activeWindowId,
      addWindow,
      removeWindow,
      activateWindow,
      minimizeWindow,
      maximizeWindow,
      restoreWindow,
      updateWindowPosition,
      updateWindowSize,
      getComponentForAppId,
      // Add new functions to context
      snapWindowToPosition,
      arrangeWindows,
      saveWindowState,
      restoreWindowState,
      focusNextWindow,
      focusPreviousWindow,
      getWindowsByAppId,
      minimizeAllWindows,
    }),
    [
      windows,
      activeWindowId,
      addWindow,
      removeWindow,
      activateWindow,
      minimizeWindow,
      maximizeWindow,
      restoreWindow,
      updateWindowPosition,
      updateWindowSize,
      getComponentForAppId,
      // Add new dependencies
      snapWindowToPosition,
      arrangeWindows,
      saveWindowState,
      restoreWindowState,
      focusNextWindow,
      focusPreviousWindow,
      getWindowsByAppId,
      minimizeAllWindows,
    ]
  );

  return (
    <WindowManagerContext.Provider value={contextValue}>
      {children}
    </WindowManagerContext.Provider>
  );
};

// Custom hook to use the Window Manager context
export const useWindowManager = () => {
  const context = useContext(WindowManagerContext);
  if (context === undefined) {
    throw new Error(
      "useWindowManager must be used within a WindowManagerProvider"
    );
  }
  return context;
};
