import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  ReactNode,
  useEffect,
  useRef,
} from "react";
import { LogEntry, LogLevel } from "../logging/types"; // Assuming types.ts is in ../logging

// Define the expected structure of raw logs coming from Pino/WebSocket
interface RawPinoLog {
  level: string; // Pino level label (e.g., "INFO", "ERROR")
  time: number; // Pino epoch timestamp (ms)
  pid?: number;
  hostname?: string;
  component_id?: string; // Added via createLogger or manually
  msg: string; // Pino message
  // ... other potential pino fields captured as 'details'
  [key: string]: any;
}

interface LoggingContextState {
  logs: LogEntry[];
  isConnected: boolean; // Add connection status
  // addLog: (log: Omit<LogEntry, 'id' | 'timestamp'>) => void; // Keep if frontend logging is also desired
}

const LoggingContext = createContext<LoggingContextState | undefined>(
  undefined
);

interface LoggingProviderProps {
  children: ReactNode;
  maxLogs?: number; // Optional limit for the number of logs to keep
  websocketUrl?: string; // URL for the backend log server
}

const LOGGING_SYSTEM_SOURCE = "LoggingSystem"; // Constant for frontend logs

export const LoggingProvider: React.FC<LoggingProviderProps> = ({
  children,
  maxLogs = 1000,
  websocketUrl = `ws://localhost:${
    import.meta.env.VITE_REACT_APP_ARCHON_PORT || 8080
  }`, // Default WebSocket URL
}) => {
  const [logs, setLogs] = useState<LogEntry[]>([]);
  const [isConnected, setIsConnected] = useState(false);
  const ws = useRef<WebSocket | null>(null);

  // Function to add logs (used by both WS and potentially internal calls)
  const addLogInternal = useCallback(
    (logEntry: LogEntry) => {
      setLogs((prevLogs) => {
        const updatedLogs = [logEntry, ...prevLogs];
        // Trim logs if exceeding maxLogs
        if (updatedLogs.length > maxLogs) {
          return updatedLogs.slice(0, maxLogs);
        }
        return updatedLogs;
      });
    },
    [maxLogs]
  );

  // Function to parse raw Pino log and convert to LogEntry
  const parseAndAddPinoLog = useCallback(
    (rawData: string) => {
      try {
        const pinoLog: RawPinoLog = JSON.parse(rawData);

        // Basic validation
        if (!pinoLog.time || !pinoLog.level || !pinoLog.msg) {
          console.warn("[LoggingContext] Received invalid log data:", pinoLog);
          // Add a generic error log?
          addLogInternal({
            id: `fe-log-${Date.now()}-${Math.random()
              .toString(36)
              .substring(2, 9)}`,
            timestamp: Date.now(),
            source: LOGGING_SYSTEM_SOURCE,
            level: "WARN",
            message: "Received malformed log data from backend.",
            details: { rawData },
          });
          return;
        }

        // Map Pino fields to LogEntry fields
        const {
          level,
          time,
          component_id,
          msg,
          pid,
          hostname,
          ...otherDetails
        } = pinoLog;

        // Combine explicit fields (pid, hostname) with other details
        const details = {
          ...(pid !== undefined && { pid }),
          ...(hostname !== undefined && { hostname }),
          ...otherDetails,
        };

        const logEntry: LogEntry = {
          id: `be-log-${time}-${Math.random().toString(36).substring(2, 9)}`,
          timestamp: time,
          source: component_id || "UNKNOWN_BACKEND",
          level: level.toUpperCase() as LogLevel,
          message: msg,
          // Use the combined details object
          details: Object.keys(details).length > 0 ? details : undefined,
        };

        // Validate level before adding
        const validLevels: LogLevel[] = [
          "INFO",
          "WARN",
          "ERROR",
          "DEBUG",
          "SYSTEM",
        ]; // Add 'CRITICAL' if Pino uses it
        if (!validLevels.includes(logEntry.level)) {
          console.warn(
            `[LoggingContext] Received log with unknown level '${logEntry.level}'. Mapping to INFO.`,
            pinoLog
          );
          logEntry.level = "INFO"; // Default to INFO or handle differently
        }

        addLogInternal(logEntry);
      } catch (error) {
        console.error(
          "[LoggingContext] Error parsing log data from WebSocket:",
          error,
          rawData
        );
        addLogInternal({
          id: `fe-log-${Date.now()}-${Math.random()
            .toString(36)
            .substring(2, 9)}`,
          timestamp: Date.now(),
          source: LOGGING_SYSTEM_SOURCE,
          level: "ERROR",
          message: "Failed to parse log data from backend.",
          details: { error: String(error), rawData },
        });
      }
    },
    [addLogInternal]
  );

  // Effect for WebSocket connection management
  useEffect(() => {
    if (!websocketUrl) return;

    // Add a small delay before attempting to connect
    const connectTimeout = setTimeout(() => {
      if (
        ws.current &&
        ws.current.readyState !== WebSocket.OPEN &&
        ws.current.readyState !== WebSocket.CONNECTING
      ) {
        // Clean up old socket if necessary before creating new one
        ws.current.close();
      }

      // Only create a new WebSocket if one isn't already connecting or open
      if (
        !ws.current ||
        ws.current.readyState === WebSocket.CLOSED ||
        ws.current.readyState === WebSocket.CLOSING
      ) {
        ws.current = new WebSocket(websocketUrl);
        console.log(
          `[LoggingContext] Attempting to connect to ${websocketUrl}...`
        );
        addLogInternal({
          id: `fe-log-${Date.now()}-${Math.random()
            .toString(36)
            .substring(2, 9)}`,
          timestamp: Date.now(),
          source: LOGGING_SYSTEM_SOURCE,
          level: "INFO",
          message: `Connecting to log stream at ${websocketUrl}...`,
        });

        ws.current.onopen = () => {
          console.log("[LoggingContext] WebSocket connected");
          setIsConnected(true);
          addLogInternal({
            id: `fe-log-${Date.now()}-${Math.random()
              .toString(36)
              .substring(2, 9)}`,
            timestamp: Date.now(),
            source: LOGGING_SYSTEM_SOURCE,
            level: "INFO",
            message: "Log stream connection established.",
          });
        };

        ws.current.onclose = () => {
          console.log("[LoggingContext] WebSocket disconnected");
          setIsConnected(false);
          // ws.current = null; // Don't nullify immediately for reconnect logic
          addLogInternal({
            id: `fe-log-${Date.now()}-${Math.random()
              .toString(36)
              .substring(2, 9)}`,
            timestamp: Date.now(),
            source: LOGGING_SYSTEM_SOURCE,
            level: "WARN",
            message: "Log stream connection closed. Retrying in 5s...",
          });
          // Simple reconnect logic: try again after 5 seconds
          // More robust logic would use exponential backoff
          setTimeout(() => {
            // Trigger useEffect again by changing dependency (or manage state)
            // For simplicity, let's just rely on the effect re-running if the component re-renders
            // A dedicated reconnect function would be better
            console.log("[LoggingContext] Attempting reconnect...");
            // Force effect re-run? - Can be tricky. A state variable might be better.
            // Or simply call the connection logic again if ws.current is closed.
            if (ws.current?.readyState === WebSocket.CLOSED) {
              // Manually trigger a new connection attempt (crude)
              const event = new Event("reconnect");
              window.dispatchEvent(event);
            }
          }, 5000);
        };

        ws.current.onerror = (error) => {
          console.error("[LoggingContext] WebSocket error:", error);
          // setIsConnected(false); // Don't set false immediately, onclose will handle it
          addLogInternal({
            id: `fe-log-${Date.now()}-${Math.random()
              .toString(36)
              .substring(2, 9)}`,
            timestamp: Date.now(),
            source: LOGGING_SYSTEM_SOURCE,
            level: "ERROR",
            message: "Log stream connection error.",
            // details: { error: String(error) } // Avoid logging the full event object
          });
          // ws.current?.close(); // Ensure cleanup on error before close fires
        };

        ws.current.onmessage = (event) => {
          // console.log('[LoggingContext] Received raw:', event.data);
          parseAndAddPinoLog(event.data);
        };
      }
    }, 500); // Delay connection attempt slightly

    // Crude reconnect listener
    const handleReconnect = () => {
      if (!ws.current || ws.current.readyState === WebSocket.CLOSED) {
        console.log("[LoggingContext] Reconnect event triggered.");
        // Re-initiate connection logic - this will trigger the effect again if dependencies change
        // Or call a dedicated connect function
        // For simplicity here, let's assume re-render or state change triggers the effect.
      }
    };
    window.addEventListener("reconnect", handleReconnect);

    // Cleanup on unmount
    return () => {
      clearTimeout(connectTimeout);
      window.removeEventListener("reconnect", handleReconnect);
      ws.current?.close();
      ws.current = null; // Ensure cleanup
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [websocketUrl, parseAndAddPinoLog]); // Re-run effect if URL changes (removed addLogInternal to prevent loops)

  // Optional: Keep the frontend addLog function if needed for UI events
  const addFrontendLog = useCallback(
    (logData: Omit<LogEntry, "id" | "timestamp">) => {
      const newLog: LogEntry = {
        ...logData,
        id: `fe-log-${Date.now()}-${Math.random()
          .toString(36)
          .substring(2, 9)}`,
        timestamp: Date.now(),
      };
      addLogInternal(newLog);
    },
    [addLogInternal]
  );

  // Example log on initialization (using frontend logger)
  useEffect(() => {
    addFrontendLog({
      source: LOGGING_SYSTEM_SOURCE,
      level: "INFO",
      message: "Frontend Logging context initialized.",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Run only once on mount

  const contextValue = {
    logs,
    isConnected,
    // addLog: addFrontendLog, // Expose frontend logger if needed
  };

  return (
    <LoggingContext.Provider value={contextValue}>
      {children}
    </LoggingContext.Provider>
  );
};

export const useLogging = () => {
  const context = useContext(LoggingContext);
  if (context === undefined) {
    throw new Error("useLogging must be used within a LoggingProvider");
  }
  return context;
};
